import logo from "./logo.svg";
import "./App.css";

import backgroundVideo from "./assets/finalBackgroundVideo.mp4";
import appLogo from "./assets/finalLogo.png";
import downArrow from "./assets/downArrow.svg";

// Images of Laptops
import mapsLaptop from "./assets/nonRendered/maps.png";
import careerLaptop from "./assets/nonRendered/career.png";
import seasonsLaptop from "./assets/nonRendered/seasons.png";
import weaponsLaptop from "./assets/nonRendered/weapons.png";
import threeDMaps from "./assets/nonRendered/mapViewer.png";
import promo from "./assets/Promo2.png";

function App() {
  return (
    <div className="App" >
      <div
        id="big"
        style={{
          width: "100vw",
          height: "100vh",
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            position: "absolute",
            width: "100vw",
            height: "100vh",
            overflow: "hidden",
          }}
        >
          <video
            style={{
              position: "absolute",
              top: "-10%",
              left: "-10%",
              width: "120%",
              height: "120%",
              objectFit: "cover",
              filter: "blur(10px) brightness(0.5)",
            }}
            src={backgroundVideo}
            autoPlay
            loop
            muted
            playsInline
            preload="auto"
          />
        </div>
        <img src={appLogo} style={{ zIndex: "10" }} className="ccLogo" />

        <p
          style={{ zIndex: "10", marginTop: "150px", color: "white" }}
          className="KriviText"
        >
          NEW EXPERIENCE.
        </p>
        <p style={{ zIndex: "10" }} className="RedKriviText">
          COMING WINTER 2024.
        </p>
        <img
          src={downArrow}
          style={{
            zIndex: "10",
            marginTop: "100px",
            width: "50px",
            height: "50px",
            animation: "bounce 1s infinite alternate",
          }}
        />
      </div>
      <style>
        {`
          @keyframes bounce {
            0% {
              transform: translateY(0);
            }
            50% {
              transform: translateY(-10px);
            }
            100% {
              transform: translateY(0);
            }
          }
          `}
      </style>

      <div id="big" className="glavniBox">
        <div className="oneHalf">
          <div className="totalTextHolder">
            <p className="redBigText">TRACK YOUR STATS LIKE NEVER BEFORE.</p>
            <p className="descriptionText" style={{ margin: "20px 0" }}>
              Discover your performance across Destiny 2 maps with detailed
              analytics. Check your KD for each map, earn collectible badges and
              achievements, look which maps you need to improve on, and see more
              expanded stats like never before.
            </p>
          </div>
        </div>
        <div
          className="secondHalf"
          style={{
            display: "flex",
            alignItems: "center", // Use alignItems to center vertically in flex container
            justifyContent: "center",
            overflow: "hidden", // Prevent content overflow
          }}
        >
          <img
            src={mapsLaptop}
            style={{
              height: "auto",
              flexShrink: "0",
            }}
            className="screenshot"
          />
        </div>
      </div>

      <div id="big" className="glavniBox">
        <div
          className="half secondHalf"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
            flexDirection: "row",
          }}
        >
          <img
            src={careerLaptop}
            style={{
              height: "auto",
              flexShrink: "0",
            }}
            className="screenshot"
          />
        </div>
        <div className="half oneHalf">
          <div className="totalTextHolder">
            <p className="redBigText">DETAILED LOOK INTO YOUR RECENT GAMES!</p>
            <p className="descriptionText" style={{ margin: "20px 0" }}>
              Explore your recent Crucible matches with our detailed analytics!
              On your Career page, you'll find breakdowns of your gameplay,
              including key statistics like kills, deaths, assists, and overall
              performance, for every gamemode.
            </p>
          </div>
        </div>
      </div>

      <div id="big" className="glavniBox">
        <div className="oneHalf">
          <div className="totalTextHolder">
            <p className="redBigText">
              SEASONAL OR EPISODIC - WE'VE GOT YOU COVERED
            </p>
            <p className="descriptionText" style={{ margin: "20px 0" }}>
              Unlock a complete view of your seasonal performance. Analyze your
              KD, grind for achievements, monitor your games, and delve into an
              array of detailed statistics for each season.{" "}
            </p>
          </div>
        </div>
        <div
          className="secondHalf"
          style={{
            display: "flex",
            alignItems: "center", // Use alignItems to center vertically in flex container
            justifyContent: "center",
            overflow: "hidden", // Prevent content overflow
          }}
        >
          <img
            src={seasonsLaptop}
            style={{
              height: "auto",
              flexShrink: "0",
            }}
            className="screenshot"
          />
        </div>
      </div>

      <div id="big" className="glavniBox">
        <div
          className="half secondHalf"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
            flexDirection: "row",
          }}
        >
          <img
            src={weaponsLaptop}
            style={{
              height: "auto",
              flexShrink: "0",
            }}
            className="screenshot"
          />
        </div>
        <div className="half oneHalf">
          <div className="totalTextHolder">
            <p className="redBigText">
              WEAPONS, WEAPONS AND EVEN MORE WEAPONS!
            </p>
            <p className="descriptionText" style={{ margin: "20px 0" }}>
              For the first time ever, you'll be able to track your weapon usage
              across all game modes. See which weapons you're most effective
              with, and which ones you need to hit the shooting range with.
            </p>
          </div>
        </div>
      </div>

      <div id="big" className="glavniBox">
        <div className="half oneHalf">
          <div className="totalTextHolder">
            <p className="redBigText">VIEW IN-GAME MAPS ON THE WEB!</p>
            <p className="descriptionText" style={{ margin: "20px 0" }}>
              Fully interactive 3D maps. Explore every nook and cranny of your
              favorite maps, and discover new strategies, choke points, angles
              and more.
            </p>
          </div>
        </div>
        <div
          className="half secondHalf"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
            flexDirection: "row",
          }}
        >
          <img
            src={threeDMaps}
            style={{
              height: "auto",
              flexShrink: "0",
            }}
            className="screenshot"
          />
        </div>
      </div>
      <div id="big" className="glavniBox">
        <div
          className="half secondHalf"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
            flexDirection: "row",
          }}
        >
          <img
            src={promo}
            style={{
              height: "auto",
              flexShrink: "0",
            }}
            className="screenshot"
          />
        </div>
        <div className="half oneHalf">
          <div className="totalTextHolder">
            <p className="redBigText">GET AN EXCLUSIVE BADGE TODAY!</p>
            <p className="descriptionText" style={{ margin: "20px 0" }}>
              To celebrate the development of Crucible Central, you can grab an
              exclusive badge on Lowman Central. You can only choose one of the
              classes, so choose wisely!
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                height: "30px",
                gap: "3.33%",
              }}
            >
              <button
                style={{ width: "30.33%", cursor: "pointer" }}
                onClick={() =>
                  (window.location.href =
                    "https://titan.crucible-central.com/login")
                }
              >
                Titan
              </button>

              <button
                style={{ width: "30.33%", cursor: "pointer" }}
                onClick={() =>
                  (window.location.href =
                    "https://warlock.crucible-central.com/login")
                }
              >
                Warlock
              </button>

              <button
                style={{ width: "30.33%", cursor: "pointer" }}
                onClick={() =>
                  (window.location.href =
                    "https://hunter.crucible-central.com/login")
                }
              >
                Hunter
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;